/* Overrides */
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 4rem;
  color: #254573;
}

h2 {
  font-size: 2rem;
  color: #99a8bd;
  font-weight: 100;
}

h3 {
  font-size: 1.8rem;
  color: #254573;
  font-weight: 100;
}

h4 {
  font-size: 1.2rem;
  font-weight: bold;
  color: #254573;
}

h5 {
  font-size: 1rem;
  font-weight: bold;
  color: #254573;
  display: inline;
}

/* Custom classes */
.full-height-container {
  height: 100vh;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.full-height-container-with-nav {
  height: calc(100vh - 68px) !important;
}

.shadowed {
  box-shadow: 0 0 4px 1px #ccc;
}

.padded {
  margin: 16px;
  padding: 16px;
}

.content {
  /* margin-top: 128px !important; */
  /* width: 80vw; */
  /* margin: 0 auto; */
}

.list-result-row {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 32px;
  border-bottom: 1px solid #d6d8db;
}

.list-result-content > span {
  line-height: 0.5rem;

}

.list-result-content > h4 {
  padding: 0;
  margin: 0;
}

.navBar {
  height: 68px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 8px;
}

.login-sidebar {
  background-color: #003333;
  background-image: url('/images/login-sidebar.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.no-margin {
  margin: 0 !important;
}

.vertical-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@media only screen and (min-width: 414) {}