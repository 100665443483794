.action-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.action-buttons > button {
    margin: 0 16px;
}

.empty-cell {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.total-summary {
    text-align: right;
}

.bill-container {
    min-height: 70vh;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
}

.services-list {
    height: 60vh;
    overflow-x: auto;
}

.services-content {
    margin: 0 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.quantity-cell {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
}